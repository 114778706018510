<template>
  <div class="thumbnails">
    <div class="banner thumbnails__imgWrap thumbnails__imgWrap_full">
<!--      <img class="banner__img thumbnails__img" src="../assets/img/banners/application_status-banner.jpg"
           alt="application status">-->
    </div>
    <transition name="fade">
      <Form action="" class="form form_box" @submit="handleStatus">
        <fieldset>
          <legend class="form__title">
            <h1 class="titlePage titlePage_textLeft">Check your application status</h1>
          </legend>
        </fieldset>

        <label class="form__label">
          <span class="form__labelTitle text_white">E-mail</span>
          <Field type="text" class="form__input"
                 placeholder="E-mail"
                 name="email"
                 rules="required|email"
                 v-model="email"
                 :disabled="getMessage !== '' && getMessage.code == 'approved'"
          />
          <ErrorMessage class="form__error"  name="email" />
        </label>

        <div class="form__warningBox" v-if="getMessage !== ''">
          <h2 class="titlePage_green titlePage_textLeft">{{getMessage.title}}</h2>
          <p class="text" v-for="item in getMessage.text">{{item}} </p>
          <div v-if="getMessage.code === 'approved'" class="row">
            <div class="col-12">
              <button type="submit" class="btn btn_transparent" @click="resend" v-if="getMessage.no_password === true"><svg-icon name="solid/envelope"/> Re-send email</button>
              <button class="btn btn_transparent ml-1" @click="toLogin"><svg-icon name="solid/right-to-bracket" /> Login</button>
            </div>
          </div>
          <!--<router-link-->
            <!--v-if="getMessage.link"-->
            <!--class="text text_green"-->
            <!--:to="getMessage.link"-->
          <!--&gt;{{getMessage.linkText}}-->
          <!--</router-link>-->
        </div>
        <button type="submit" class="btn btn_transparent" v-if="!getMessage || getMessage.code !== 'approved'">Check application</button>
      </Form>
    </transition>
  </div>
</template>

<script>
  export default {
    name: "Status",

    data() {
      return {
        email: '',
      }
    },

    methods: {
      handleStatus() {
      /*  this.$validator.validate().then(valid => {
          if (valid) {*/
            this.$store.dispatch('status/sendEmail', {
              email: this.email,
            });
         /* }
        });*/
      },
        toLogin(){
          this.$store.commit('status/clearMessage')
          this.$router.push({name: 'login'})
        },
        resend(){
            this.$store.dispatch('status/resendEmail', {
                email: this.email
            })
        }
    },

    computed: {
      getMessage() {
        return this.$store.getters['status/message'];
      }
    },
      beforeDestroy(){
          this.$store.commit('status/clearMessage')
      }
  }
</script>

<style scoped lang="scss">
  @import "../assets/scss/utils/vars";
  .text {
    display: inline;
    &_green {
      color: $green;
      display: inline;
    }
  }

  .thumbnails__img{
    object-position: right;
  }

  @media screen and (min-width: $el) {
    .form_box {
      transform: translateY(-50%) translateX(80%);
    }
  }

  @media screen and (max-width: $l){
    .thumbnails__img{
      object-position: center;
    }
  }




</style>
